import "./Landing.css";
import forest from "../../assets/images/forest.jpg";
import { Button } from "../../components/Button/ButtonComponent";

function LandingFrameMessage() {
  const style = {
    
  };
  return (
    <div className="landingPageText">
      <div className="landingPageText__big">Luleå Miljö Taxi</div>
      <div className="landingPageText__small">Ditt miljövänliga alternativ</div>
      <div className="landingPageText__phonenumber">0920-51 00 00</div>
      <br />
      <Button />
    </div>
  );
}

function LandingFrame() {
  return (
    <div className="background">
      <LandingFrameMessage />
    </div>
  );
}

function HomePage() {
  function addComponent() {}
  return (
    <div class="flex">
      <LandingFrame />
      <div class="flex">
        <Button onClick={addComponent} text="Call component"></Button>
      </div>
    </div>
  );
}
export default HomePage;
