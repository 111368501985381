import React from "react";
import "./ButtonComponent.css";

const Button = (props) => {
  return <button class="button">
    <a href="tel:+46920510000">Ring oss</a>
    </button>;
};

export { Button };
